import { Input, InputRef } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useT } from 'apprise-frontend-core/intl/language'
import { usePrevious } from 'apprise-frontend-core/utils/hooks'
import { Button } from 'apprise-ui/button/button'
import { Sized, Tall, Wide, antsizeOf } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded, Uncontrolled } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { SuffixHelper } from 'apprise-ui/field/suffixhelper'
import { RemoveItemIcon } from 'apprise-ui/utils/icons'
import * as React from 'react'
import "./styles.scss"

//  this wraps over text inputs and text areas.
//  they are 'partially controlled': clients provide the initial value
//  and are notified of changes as soon the user stops typing, like with conventional uncontrolled components.
//  if the client provides a different input, however, the state is immediately reset to match it,
//  like in a conventional controlled component.

export type TextboxProps<T = string> = Fielded<T> & ChangeTracked<T> & Sized & Wide & Tall & Uncontrolled & Partial<{

    prefix: React.ReactNode

    placeholder: true | string

    // number = rows and draggable, true=autosize, object=presize then scroll.
    multi: number | boolean | { start?: number, end?: number }

    children: T

    onRemove: () => any


}>

export type TextBoxApi = {

    focus: () => void
}


// a simpler type to assert instead the lossy typing of forwardref
type TextBoxComponent = (_: TextboxProps & { ref?: React.Ref<TextBoxApi | undefined> }) => JSX.Element


export const TextBox = React.forwardRef((clientprops: TextboxProps, clientref: React.Ref<TextBoxApi | undefined>) => {

    const t = useT()

    const trackedOnChange = clientprops.onChange ? ((v: string | undefined) => {

        lastChange.current = v

        clientprops.onChange?.(v)

    }) : undefined

    const props = useFieldProps({ ...clientprops, onChange: trackedOnChange, delay: clientprops.delay ?? true })

    const { pastMode, pastValue } = useChangeHelper(props)

    useReadonlyHelper(props)

    useResetHelper(props)

    const {

        multi, prefix,

        children, defaultValue, placeholder,

        onChange, onRemove, cancelDebouncedChange,

        height, size,

        innerClassName, innerStyle,

        readonly, disabled

    } = props

    // we use this to inspect and reset the internal state of the field.
    const ref = React.useRef<InputRef>(null)

    React.useImperativeHandle(clientref, () => ({

        focus: () => ref.current?.focus()

    }))

    const currentValue = children ?? defaultValue

    const rows = typeof multi === 'number' ? multi : 1

    const autoSize = typeof multi === 'number' ? false :    // fixed number of rows
        typeof multi === 'boolean' ?            // no specific directives
            height ? false                    // indirectly, height says no autosize.

                : { minRows: 2 }                      // no height, then at least a couple of rows to show it's an area. 

            : { minRows: multi?.start, maxRows: multi?.end }       //no, we have specific size directives.


    const initial = React.useRef(children)

    const latestValue = pastMode ? pastValue : currentValue

    const lastValue = usePrevious(latestValue)

    // track the last value reported by the field, so that we can tell if we'rendering an internal change (comes from the filed) 
    // or an external change (e.g. a reset).
    const lastChange = React.useRef<string | undefined>(latestValue)

    const [key, changeKey] = React.useState(0)

    // resyncs field with an external change   
    //eslint-disable-next-line
    React.useEffect(() => {

        if (latestValue !== lastChange.current) {

            cancelDebouncedChange()

            lastChange.current = latestValue

            changeKey(s => ++s)
        }

    })


    const change = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        const value = e.target.value

        // we revert empty string to undefined if that was the initial value, so we avoid false positives in change tracking. 
        // but we leave it empty if we have a default value, so don't end up resetting it if we wanted instead to empty the field.
        const initialNotEmpty = initial.current && initial.current.trim().length > 0
        const currentNotEmpty = value.trim().length > 0
        const normalised = currentNotEmpty || initialNotEmpty || defaultValue ? value : initial.current

        onChange?.(normalised)
    }

    const removable = !!onRemove

    const removeBtn = <Button className='suffix-remove-lang' type='ghost' noReadonly={true} onClick={onRemove}>
        <RemoveItemIcon color='orange' />
    </Button>


    const commonProps = {

        ref: ref as any,
        

        className: innerClassName,
        style: { ...innerStyle, height: multi && height ? height : 'auto' },
        size: antsizeOf(size),

        defaultValue: latestValue,
        onChange: change,

        disabled: disabled,
        readOnly: readonly,

        placeholder: placeholder === true ? t('ui.field_placeholder') : placeholder

    }

    const content = multi ? <TextArea key={key} {...commonProps} rows={rows} autoSize={autoSize} /> : <Input key={key} {...commonProps} prefix={prefix} />


    props.debug && console.log({ currentValue, pastMode, latestValue, pastValue, lastValue })



    return <Field name="textbox" {...props} >
        <SuffixHelper mask show={removable} suffix={removeBtn}>
            {content}
        </SuffixHelper>
    </Field>

}) as TextBoxComponent