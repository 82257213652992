import { useCall } from 'apprise-frontend-core/client/call'
import { TagCategory } from '../category/model'

const svc = 'admin'
export const tagcategoryapi = "/tagcategory"

export const useCategoryCalls = () => {

    const call = useCall(svc)

    const self = {

      
        
        fetchAllCategories: () => call.at(tagcategoryapi).get<TagCategory[]>()

        ,

        addCategory: (category:TagCategory)  =>  call.at(tagcategoryapi).post<TagCategory>(category)
        
        ,  

        updateCategory: (category:TagCategory)  => call.at(`${tagcategoryapi}/${category.id}`).put<TagCategory>(category)
       
        ,

        updateCategories: (categories:TagCategory[])  => call.at(`${tagcategoryapi}`).put<TagCategory[]>(categories)
       
        ,  

        deleteCategory: (category:TagCategory)  =>  call.at(`${tagcategoryapi}/${category.id}`).delete()

    }

    return self;

}
