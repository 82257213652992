import { Action } from 'apprise-frontend-core/authz/action';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { FormState } from 'apprise-frontend-core/utils/form';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import { PermissionBox, PermissionBoxProps } from 'apprise-frontend-iam/permission/permissionbox';
import { UserPermissionModuleContext, UserPreferencesModuleContext } from 'apprise-frontend-iam/user/provider';
import { CategoryLabel } from 'apprise-frontend-tags/category/label';
import { TagCategory } from 'apprise-frontend-tags/category/model';
import { useCategoryStore } from 'apprise-frontend-tags/category/store';
import { CategoryIcon, categoryPlural, categorySingular, tagType } from 'apprise-frontend-tags/constants';
import { categoryActions } from 'apprise-frontend-tags/oracle';
import { Fields } from 'apprise-ui/field/validation';
import React, { FC } from 'react';
import { IconBaseProps } from 'react-icons';
import { User } from './model';
import { SheetWriter } from 'apprise-frontend-parse/workbookWriter';
import { DownloadOptions } from './download';

export type UserPreferencesModule = {

    Form: FC<FormState<User>>
    fields?: (_: FormState<User>) => Fields
    columns?: JSX.Element[]
    download?: (_: SheetWriter<User>, __: DownloadOptions) => void
    
}


export const useUserPreferencesModules = () => {

    const state = React.useContext(UserPreferencesModuleContext)

    const self = {

        all: () => state.get().modules

        ,

        register: (module: UserPreferencesModule) => state.set(s => s.modules = [...s.modules, module])

    }

    return self
}


export type UserPermissionBoxProps<R = any> = Partial<PermissionBoxProps<R>> & {

    edited: User
}
/* ----------------------------- */
export type UserPermissionModule<R> = {

    type: string
    Icon: React.FC<IconBaseProps>
    plural: string,
    maxPrivilege?: Action
    initial?: (_: User) => Action[]

    // client can show certain permissions based on tenancy properties.
    renderIf? : (oracle:ReturnType<typeof useTenancyOracle>, user: User) => boolean

    box: (_: UserPermissionBoxProps<R>) => JSX.Element | undefined

}



export const useUserPermissionModules = () => {

    const state = React.useContext(UserPermissionModuleContext)

    const self = {

        all: () => state?.get().modules

        ,

        register: (module: UserPermissionModule<any>) => state.set(s => s.modules?.push(module))

        ,

        initial: (user: User) => self.all()?.reduce((acc, m) => [...acc, ...m.initial?.(user) ?? []], [] as Action[]) ?? []

    }

    return self;
}

// returns the module for tags, which are a dependency, on behalf of apprise-frontend-tags.
// this avoid a dependency cycle with apprise-frontend-tags (which is easier than handling the cycle).
// note: must be installed after the logged user is loaded.
export const useTagPermissionModule = (): UserPermissionModule<TagCategory> => {

    return {

        type: tagType,
        Icon: CategoryIcon,
        plural: categoryPlural,
        box: TagPermissionBox,

    }


}

const TagPermissionBox = (props: Partial<PermissionBoxProps<TagCategory>>) => {

    const oracle = useTenancyOracle()

    const store = useCategoryStore()
    const l = useL()

    const { userRange, ...rest } = props

    // not visible to a tenant user.
    if (oracle.isTenantUser())
        return undefined

    // not over tenant users either, filter them out from the range.
    // eslint-disable-next-line
    const filteredRange = userRange?.filter(u => oracle.given(u).hasNoTenant())

    // none left? (eg. user-centric view)
    if (filteredRange?.length === 0)
        return undefined

    return <PermissionBox preselectWildcard
        resourcePlural={categoryPlural} resourceSingular={categorySingular}
        resourceRange={store.allCategories()} 
        resourceId={c => c.id}
        resourceText={c => l(c.name) }
        resourceRender={c => <CategoryLabel bare noLink category={c} />}
        userRange={filteredRange}
        permissionRange={Object.values(categoryActions)}
        maxPrivilege={categoryActions.manage}
        {...rest} />

}