
import { usePreload } from 'apprise-frontend-core/client/preload';
import { useConfig } from 'apprise-frontend-core/config/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { useComponentBridge } from 'apprise-frontend-core/utils/bridge';
import { useBusyState } from 'apprise-frontend-core/utils/busyguard';
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard';
import i18next, { ResourceKey } from "i18next";
import http from "i18next-http-backend";
import { useEffect, useState } from 'react';
import { initReactI18next } from "react-i18next";
import { useToolBridge } from '../utils/toolbridge';
import { IntlConfiguration, RemoteIntlConfiguration } from './config';
import { useLanguage } from './language';
import { InltContext, initialIntlState, useIntl, useIntlConfig } from './state';

export type IntlProps = React.PropsWithChildren<Partial<{

    config: Partial<IntlConfiguration>
    translations: Record<string, ResourceKey>

}>>


// mounts state, if we haven't already, then initialise configuration and loads trnaslations.
export const Intl = (props: IntlProps) => {

    const { children, ...rest } = props

    return <StateProvider initialState={initialIntlState} context={InltContext}>
        <IntlInitialiser {...props}>
            <IntlLoader {...rest}>
                {children}
            </IntlLoader>
        </IntlInitialiser>
    </StateProvider>

}



// overlays client configuration, if we have any.
// then syncs with changes to global configuration and overlays those too.
const IntlInitialiser = (props: IntlProps) => {

    const intl = useIntl()

    // looks in global configuration, under a prefix. may be undefined until loaded.
    const remoteConfig = useConfig<RemoteIntlConfiguration>().intl

    const { config: clientconfig = {}, children } = props

    const [active, activeSet] = useState(false)

    // updates in sync with global config, typically when the latter is loaded.
    useEffect(() => {

        if (active) {
            console.log("re-initialising intl with remote configuration...")
            intl.init(remoteConfig)
        }

        // eslint-disable-next-line
    }, [remoteConfig])

    // initialises module.
    const activate = () => {

        intl.init(clientconfig)

        activeSet(true)

    }

    // activates module before rendering children.
    const { content } = useRenderGuard({

        when: active,

        render: children,

        orRun: activate
    })

    return content
}


const IntlLoader = (props: IntlProps) => props.translations ? <LocalIntlLoader {...props} /> : <RemoteIntlLoader {...props} />

const LocalIntlLoader = (props: IntlProps) => {

    const { children, translations = {} } = props

    const resources = Object.entries(translations).reduce((acc, [lang, content]) => ({ ...acc, [lang]: { translation: content } }), {})

    const lang = useLanguage()

    const intl = useIntl()

    // if available, we use the busy-wait system to fetch translations.
    const busy = useBusyState()

    const activate = async () => {

        var error: any = undefined;

        return busy.toggle("translations")
            .then(() =>

                i18next
                    .use(initReactI18next) // passes i18n down to react-i18next
                    .init({

                        
                        partialBundledLanguages: true,

                        returnEmptyString: false,
                       
                        fallbackLng: lang.default(),
                        react: {
                            useSuspense: false
                        },

                        debug: false,
                        nsSeparator: "::",
                        resources,
                        interpolation: {

                            escapeValue: false,
                            format: function (value, format) {
                                if (format === 'uc') return value.toUpperCase();
                                if (format === 'lc') return value.toLowerCase();
                                return value;
                            }

                        } // react already safe from XSS

                    },
                        //  throwing errors from here won't propagae to thenables (must be caught)
                        //  so we track the error manually and throw from the first thenable.
                        e => { if (e) error = new Error(e) }

                    ))

            .then(() => { if (error) throw error })

            .then(() => intl.register(i18next.t.bind(i18next), i18next.changeLanguage.bind(i18next)))

            .finally(() => busy.toggle("translations"))

    }

    // initialises i18next before rendering children.

    const { content } = useRenderGuard({

        render: children,

        orRun: activate

    })

    return content
}

const RemoteIntlLoader = (props: IntlProps) => {

    const { children } = props

    const intl = useIntl()
    const intlConfig = useIntlConfig()
    const lang = useLanguage()
    const { absoluteOf } = useToolBridge()

    // if available, we use the busy-wait system to fetch translations.
    const busy = useBusyState()

    const bridge = useComponentBridge()

    const preload = usePreload()

    const activate = async () => {

        console.log("fetching translations...")

        var error: any = undefined;

        const preloaded = Promise.all(lang.all()
            .map(lang => preload.get(`/intl/${lang}`)?.then(res => ({ [lang]: { translation: res } })) ?? Promise.resolve()))
            .then(resources => resources.reduce((acc, res) => ({ ...acc, ...res }), {}))

        const preloadedResources: any = await preloaded

        //console.log({preloadedResources})

        return busy.toggle("translations")
            .then(() =>

                i18next
                    .use(http)
                    .use(initReactI18next) // passes i18n down to react-i18next
                    .init({
                        partialBundledLanguages: true,
                        resources: preloadedResources,
                        lng: undefined,
                        returnEmptyString: false,
                        fallbackLng: lang.default(),
                        react: {
                            useSuspense: false
                        },

                        debug: false,
                        nsSeparator: "::",
                        backend: { loadPath: absoluteOf(intlConfig.path) },
                        interpolation: {

                            escapeValue: false,
                            format: function (value, format) {
                                if (format === 'uc') return value.toUpperCase();
                                if (format === 'lc') return value.toLowerCase();
                                return value;
                            }

                        } // react already safe from XSS

                    },
                        //  throwing errors from here won't propagae to thenables (must be caught)
                        //  so we track the error manually and throw from the first thenable.
                        e => { if (e) error = new Error(e) }

                    ))

            .then(() => { if (error) throw error })

            .then(() => intl.register(i18next.t.bind(i18next), i18next.changeLanguage.bind(i18next)))

            .catch(e => bridge.renderError(e, "cannot load translations.", false))  // can't possibly translate this yet.

            .finally(() => busy.toggle("translations"))

    }

    // initialises i18next before rendering children.

    const { content } = useRenderGuard({

        render: children,

        orRun: activate

    })

    return content
}
