
// we take any string-keyed object from clients.

export type Config = Partial<BaseConfig & Record<string,any>>


// the only config we know statically.
export type BaseConfig = {

  // used to introspect the runtime to enable/disable features. 
  // features only for local development? => check mode==='development'.
  // features only for development and staging? => check mode!=='production' (mode==='development' || mode==='test').

  mode: 'development' | 'staging' | 'production'

  version?: string | string[]
  latest: boolean,

  // type-indexed list of model properties that shouldn't be rendered in forms.
  propertyExcludes: Record<string, string[]>

}


// this is the rockbed to overlay any config provided by clients or returned by backend.

export const defaultBaseConfig: BaseConfig = {

    // assumes local development.
    // override for staging (mode='test') or production (mode=='production').
    mode: 'development',

    latest: true,

    propertyExcludes: {}

}
