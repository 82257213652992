import { useCall } from 'apprise-frontend-core/client/call'
import { Tag } from './model'

const svc="admin"
export const tagapi = "/tag"

export const useTagCalls = () => {

    const call = useCall(svc)


    const self = {

        fetchAllTags: () => call.at(tagapi).get<Tag[]>()
        
        ,

        addTag: (tag:Tag)  =>  call.at(tagapi).post<Tag>(tag)
        
        ,  

        updateTag: (tag:Tag)  =>  call.at(`${tagapi}/${tag.id}`).put<Tag>(tag)

        ,

        deleteTag: (tag:Tag)  =>  call.at(`${tagapi}/${tag.id}`).delete()

    }

    return self;

}

